import PropTypes from 'prop-types';
import _ from 'lodash';

import OrderSide from 'scripts/constants/OrderSide';
import OrderStatus from 'scripts/constants/OrderStatus';
import AlertType from 'scripts/constants/AlertType';
import RegistrationStep from 'scripts/constants/RegistrationStep';
import PropertyStatus from 'scripts/constants/PropertyStatus';

const OrderSideEnum = _.values(OrderSide);
const OrderStatusEnum = _.values(OrderStatus);
const AlertTypeEnum = _.values(AlertType);
const RegistrationStepEnum = _.values(RegistrationStep);
const PropertyStatusEnum = _.values(PropertyStatus);

// TODO: These need to be defined better
export const userProps = {
  id: PropTypes.string,
  email: PropTypes.string,
  givenName: PropTypes.string,
  familyName: PropTypes.string,
  dateOfBirth: PropTypes.string,
  age: PropTypes.number,
  address: PropTypes.string,
  mobilePhone: PropTypes.string,
  australianTaxResidency: PropTypes.string,
  usCitizen: PropTypes.string,
  identityCheck: PropTypes.string,
  accountActive: PropTypes.bool,
  accountStatus: PropTypes.string,
  nextRegistrationStep: PropTypes.oneOf(RegistrationStepEnum),
  firstDistributionCollected: PropTypes.bool,
  accountType: PropTypes.string,
  isBusinessAccount: PropTypes.bool,
  isAdmin: PropTypes.bool,
  financialDetails: PropTypes.object,
  emailSubscriptions: PropTypes.object,
};

export const user = PropTypes.shape(userProps);

export const memberStatus = PropTypes.shape({
  isMember: PropTypes.bool,
  isInvestor: PropTypes.bool,
});

export const resetPassword = PropTypes.shape({
  action: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired,
});

export const auth = PropTypes.shape({
  action: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    givenName: PropTypes.string.isRequired,
    familyName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    nextRegistrationStep: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string.isRequired,
  })
});

export const facebook = PropTypes.shape({
  id: PropTypes.string,
  overall_star_rating: PropTypes.number.isRequired,
});

export const propertiesRg46 = PropTypes.shape({
  id: PropTypes.string.isRequired,
  propertyCode: PropTypes.string.isRequired,
  borrowing: PropTypes.string.isRequired,
  gearingRatioCalculationBase: PropTypes.string.isRequired,
  breachOfCovenant: PropTypes.string.isRequired,
  aggregateUndrawnAmount: PropTypes.string.isRequired,
  loanToValueAndInterest: PropTypes.string.isRequired,
  dateLastChangeMade: PropTypes.date,
});

export const transaction = PropTypes.shape({
  id: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  description: PropTypes.string,
  created: PropTypes.string.isRequired,
  reconciled: PropTypes.string,
  cancelled: PropTypes.string,
});

export const detailedTransaction = PropTypes.shape({
  accountId: PropTypes.string.isRequired,
  propertyCode: PropTypes.string,
  transactionType: PropTypes.string.isRequired,
  date: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  reference: PropTypes.string.isRequired,
  status: PropTypes.string,
  pdfUrl: PropTypes.string,
  reconciledDate: PropTypes.number,
  description: PropTypes.string.isRequired
});

export const detailedTransactions = PropTypes.arrayOf(detailedTransaction);

export const depositTransaction = PropTypes.shape({
  coreTransactionId: PropTypes.string.isRequired,
  depositId: PropTypes.string.isRequired,
  isBafm: PropTypes.bool.isRequired,
  amount: PropTypes.number.isRequired
});

export const depositTransactions = PropTypes.shape({
  list: PropTypes.arrayOf(depositTransaction),
});

export const depositInfo = PropTypes.shape({
  bafmAmount: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired
});

export const transactions = PropTypes.shape({
  list: PropTypes.arrayOf(transaction),
});

export const historicalBrickValue = PropTypes.shape({
  monthYear: PropTypes.string.isRequired,
  brickValue: PropTypes.number.isRequired,
});

export const historicalBrickValues = PropTypes.arrayOf(historicalBrickValue);

export const propertyAverageAnnualGrowthMetrics = PropTypes.shape({
  '1yr': PropTypes.number,
  '5yr': PropTypes.number,
  '10yr': PropTypes.number,
  '15yr': PropTypes.number,
  '20yr': PropTypes.number,
});

export const averageAnnualGrowthMetrics = PropTypes.object;

export const historicalGrowthData = PropTypes.arrayOf(PropTypes.shape({
  state: PropTypes.string,
  postCode: PropTypes.string,
  suburb: PropTypes.string,
  propertyType: PropTypes.string,
  monthEnd: PropTypes.number,
  medianPrice: PropTypes.number,
}));

export const financialsMetrics = PropTypes.shape({
  latestTrustValuesSum: PropTypes.number.isRequired,
  netRentalIncomesSum: PropTypes.number.isRequired,
  bricksTransactedLast30Days: PropTypes.number.isRequired,
  investors: PropTypes.number.isRequired,
  members: PropTypes.number.isRequired
});

export const latestStats = PropTypes.shape({
  activeMembers: PropTypes.number.isRequired,
  bricksTransactedLast90Days: PropTypes.number.isRequired,
  totalPropertyValue: PropTypes.number.isRequired,
  medianSaleTimeMills: PropTypes.number.isRequired
});

export const distribution = PropTypes.shape({
  amount: PropTypes.number.isRequired,
  distributionDate: PropTypes.string.isRequired,
  paidDate: PropTypes.string.isRequired,
});

export const monthlyDistributions = PropTypes.arrayOf(distribution);


export const monthlyUpdate = PropTypes.shape({
  monthlyDistributionInfo: PropTypes.string.isRequired,
  brickValuationInfo: PropTypes.string.isRequired,
  additionalInfo: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired
});

export const monthlyUpdates = PropTypes.arrayOf(monthlyUpdate);

export const wallet = PropTypes.shape({
  balance: PropTypes.number.isRequired,
  transactions: PropTypes.arrayOf(transaction).isRequired
});

export const withdrawal = PropTypes.shape({
  type: PropTypes.string.isRequired,
  data: PropTypes.shape({
    amount: PropTypes.number.isRequired
  })
});

export const profile = PropTypes.shape({
  displayName: PropTypes.string.isRequired,
  legalName: PropTypes.string.isRequired
});

export const portfolio = PropTypes.shape({
  totalValue: PropTypes.number.isRequired,
  amountInvested: PropTypes.number.isRequired,
  distributionsPaid: PropTypes.number.isRequired,
  capitalReturns: PropTypes.number.isRequired,
  totalRoi: PropTypes.number.isRequired,
  totalPropertiesOwned: PropTypes.number.isRequired,
  totalBricksOwned: PropTypes.number.isRequired,
  records: PropTypes.arrayOf(PropTypes.shape({
    propertyCode: PropTypes.string.isRequired,
    propertySuburb: PropTypes.string.isRequired,
    totalBricks: PropTypes.number.isRequired,
    marketBrickValue: PropTypes.number.isRequired,
    amountInvested: PropTypes.number.isRequired,
    distributionsPaid: PropTypes.number.isRequired,
    totalRoi: PropTypes.number.isRequired,
    costBasis: PropTypes.number.isRequired,
    dateAcquired: PropTypes.string.isRequired,
  }))
});

export const account = PropTypes.shape({
  wallet: wallet.isRequired,
  profile: profile.isRequired,
  portfolio: portfolio.isRequired,
  totalAccountValue: PropTypes.number.isRequired,
  blockedAmount: PropTypes.number.isRequired,
  availableToTradeBalance: PropTypes.number.isRequired,
  isDeactivable: PropTypes.bool.isRequired
});

export const DebtTerms = PropTypes.shape({
  initialDebt: PropTypes.number,
  loanTermType: PropTypes.string,
  loanTerms: PropTypes.number,
  fixedLoanTerms: PropTypes.number,
  interestRatePerc: PropTypes.number,
  loanDescription: PropTypes.string,
  yearlyInterestPayment: PropTypes.number,
  outstandingDebt: PropTypes.number,
});

export const investmentMetrics = PropTypes.shape({
  propertyCode: PropTypes.string.isRequired,
  investors: PropTypes.number.isRequired,
  remainingBricks: PropTypes.number.isRequired
});

export const propertyImage = PropTypes.shape({
  name: PropTypes.string,
  optimizedUrl: PropTypes.string.isRequired,
  resized75Url: PropTypes.string.isRequired,
  resized297Url: PropTypes.string.isRequired,
  resized595Url: PropTypes.string.isRequired,
});

export const property = PropTypes.shape({
  id: PropTypes.number.isRequired,
  propertyCode: PropTypes.string.isRequired,
  propertyStatus: PropTypes.oneOf(PropertyStatusEnum).isRequired,
  propertyType: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
  suburb: PropTypes.string.isRequired,
  historicalGrowthKey: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  propertyFeatures: PropTypes.string.isRequired,
  bedroomCount: PropTypes.number.isRequired,
  bathroomCount: PropTypes.number.isRequired,
  carSpotCount: PropTypes.number.isRequired,
  exchangeDate: PropTypes.date,
  settlementDate: PropTypes.date,
  platformSettlementDate: PropTypes.date,
  nextValuationDate: PropTypes.date,
  propertyImages: PropTypes.arrayOf(propertyImage).isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
  financials: PropTypes.shape({
    propertyCode: PropTypes.string.isRequired,
    purchasePrice: PropTypes.number.isRequired,
    stampDuty: PropTypes.number.isRequired,
    legalProfessional: PropTypes.number.isRequired,
    buyersAgentFees: PropTypes.number.isRequired,
    otherAcquisitionCosts: PropTypes.number.isRequired,
    numberBricks: PropTypes.number.isRequired,
    weeklyRentalIncome: PropTypes.number.isRequired,
    waterRates: PropTypes.number.isRequired,
    councilRates: PropTypes.number.isRequired,
    strata: PropTypes.number.isRequired,
    repairsMaintenance: PropTypes.number.isRequired,
    landlordInsurance: PropTypes.number.isRequired,
    annualAuditValuationFees: PropTypes.number.isRequired,
    propertyLandTax: PropTypes.number.isRequired,
    otherCashFlow: PropTypes.number.isRequired,
    annualPropertyManagementFee: PropTypes.number.isRequired,
    cashReserve: PropTypes.number.isRequired,
    totalPurchasePrice: PropTypes.number.isRequired,
    equity: PropTypes.number.isRequired,
    brickPrice: PropTypes.number.isRequired,
    brickValue: PropTypes.number.isRequired,
    initialBrickPrice: PropTypes.number.isRequired,
    lowestAvailableBrickPrice: PropTypes.number,
    discountOnBrickValue: PropTypes.number,
    lastPropertyValuation: PropTypes.number.isRequired,
    latestValue: PropTypes.number.isRequired,
    liveCashReserve: PropTypes.number.isRequired,
    netRentalYield: PropTypes.number.isRequired,
    grossRentalYield: PropTypes.number.isRequired,
    annualGrossRentalIncome: PropTypes.number.isRequired,
    annualPropertyExpenses: PropTypes.number.isRequired,
    acquisitionCost: PropTypes.number.isRequired,
    unamortisedAcquisitionCosts: PropTypes.number.isRequired,
    initialEquity: PropTypes.number.isRequired,
    lVR: PropTypes.number.isRequired,
    fiveYHistoricalSuburbGrowth: PropTypes.number.isRequired,
    gearingEffect: PropTypes.number.isRequired,
    annualNetRentalIncome: PropTypes.number.isRequired,
    estimatedROI: PropTypes.number.isRequired,
    debtTerms: DebtTerms,
    oneYearChangeBrickValue: PropTypes.number,
    financialsMonth: PropTypes.string.isRequired,
    isIndependentValued: PropTypes.bool.isRequired
  }),
  geolocation: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired
  }).isRequired,
  tenantStatus: PropTypes.shape({
    tenanted: PropTypes.bool.isRequired,
    rentContractExpiryDate: PropTypes.date,
    leaseAgreementSigned: PropTypes.bool.isRequired,
    leaseTerm: PropTypes.string,
    leaseStartDate: PropTypes.date,
    leaseLength: PropTypes.number,
  }).isRequired,
  // TODO group suburb data
  suburbId: PropTypes.number.isRequired,
  investmentMetrics: investmentMetrics.isRequired
});

export const suburb = PropTypes.shape({
  type: PropTypes.string.isRequired,
  geometry: PropTypes.shape({
    type: PropTypes.string.isRequired,
    coordinates: PropTypes.array
  }),
  properties: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    // TODO: remove
    medianGrowth: PropTypes.number,
    medianYield: PropTypes.number
  })
});

export const trade = PropTypes.shape({
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired
});

export const proposedBuyOrder = PropTypes.shape({
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  totalPrice: PropTypes.number.isRequired
});

export const tradeProposal = PropTypes.shape({
  type: PropTypes.string.isRequired,
  data: PropTypes.shape({
    proposedBuyOrders: PropTypes.arrayOf(proposedBuyOrder),
    propertyCode: PropTypes.string,
    quantity: PropTypes.number.isRequired,
    quantityWillBeTransacted: PropTypes.number,
    basisPrice: PropTypes.number.isRequired,
    valueOfBricks: PropTypes.number.isRequired,
    transactionFee: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
  })
});

export const tradeSucceed = PropTypes.shape({
  type: PropTypes.string.isRequired,
  data: PropTypes.shape({
    propertyCode: PropTypes.string.isRequired,
    quantityProposed: PropTypes.number.isRequired,
    quantityTransacted: PropTypes.number.isRequired,
    basisPrice: PropTypes.number.isRequired,
    valueOfBricks: PropTypes.number.isRequired,
    transactionFee: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
  })
});

export const purchaseProposalForm = PropTypes.shape({
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  hasError: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
});

export const sellForm = PropTypes.shape({
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  transactionFee: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  hasQuantityError: PropTypes.bool.isRequired,
  hasPriceError: PropTypes.bool.isRequired,
  priceErrorMsg: PropTypes.string.isRequired,
  quantityErrorMsg: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
});

export const investor = PropTypes.shape({
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  givenName: PropTypes.string.isRequired,
  familyName: PropTypes.string.isRequired
});

export const order = PropTypes.shape({
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  orderSide: PropTypes.oneOf(OrderSideEnum).isRequired,
  commission: PropTypes.number.isRequired,
});

export const orderAggregated = PropTypes.shape({
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
});

export const orderBook = PropTypes.shape({
  orders: PropTypes.arrayOf(order),
  ordersBuy: PropTypes.arrayOf(orderAggregated),
  ordersSell: PropTypes.arrayOf(orderAggregated),
  trades: PropTypes.arrayOf(trade),
  enabled: PropTypes.bool,
});

export const orderBookStatus = PropTypes.shape({
  enabled: PropTypes.bool.isRequired
});

export const propertyTransaction = PropTypes.shape({
  propertyCode: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  timestamp: PropTypes.number.isRequired,
  transactionType: PropTypes.string.isRequired,
});

export const propertyTransactions = PropTypes.arrayOf(propertyTransaction);

export const returnsCalculatorStatus = PropTypes.shape({
  enabled: PropTypes.bool.isRequired
});

export const maintenanceStatus = PropTypes.shape({
  enabled: PropTypes.bool.isRequired
});

export const marketStatus = PropTypes.shape({
  enabled: PropTypes.bool.isRequired
});

export const promotion = PropTypes.shape({
  promotionCode: PropTypes.string.isRequired,
  description: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  promotionType: PropTypes.string,
  bonus: PropTypes.number,
  activationMsg: PropTypes.string
});

export const feeFreePromotionStatus = PropTypes.shape({
  enabled: PropTypes.bool.isRequired
});


export const bafm = PropTypes.shape({
  hasBafm: PropTypes.bool.isRequired,
  bafmLimitReached: PropTypes.bool.isRequired
});

export const bpay = PropTypes.shape({
  billerCode: PropTypes.string,
  refNumber: PropTypes.string
});

export const customerReferral = PropTypes.shape({
  id: PropTypes.string.isRequired,
  referrerId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired
});

export const referralRegistration = PropTypes.shape({
  referralRecordId: PropTypes.string.isRequired,
  referrerName: PropTypes.string.isRequired,
  activationDueDate: PropTypes.string.isRequired,
  activationProcessed: PropTypes.bool
});

export const myOrderChild = PropTypes.shape({
  orderId: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  status: PropTypes.oneOf(OrderStatusEnum).isRequired,
});

export const myOrderParent = PropTypes.shape({
  orderId: PropTypes.string.isRequired,
  propertyCode: PropTypes.string.isRequired,
  orderSide: PropTypes.oneOf(OrderSideEnum).isRequired,
  quantity: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  status: PropTypes.oneOf(OrderStatusEnum).isRequired,
  commission: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(myOrderChild)
});

export const myOrder = PropTypes.oneOfType([
  myOrderParent,
  myOrderChild
]);

export const myOrders = PropTypes.shape({
  list: PropTypes.arrayOf(myOrder),
  pendingPre: PropTypes.arrayOf(myOrder),
  pendingSell: PropTypes.arrayOf(myOrder),
});

export const myTrade = PropTypes.shape({
  id: PropTypes.string.isRequired,
  propertyCode: PropTypes.string.isRequired,
  orderSide: PropTypes.oneOf(OrderSideEnum).isRequired,
  quantity: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  tradeConfirmationPdfUrl: PropTypes.string.isRequired,
});

export const myTrades = PropTypes.shape({
  list: PropTypes.arrayOf(myTrade),
});

export const alert = PropTypes.shape({
  type: PropTypes.oneOf(AlertTypeEnum).isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
});

export const registration = PropTypes.shape({
  type: PropTypes.oneOf(RegistrationStepEnum).isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
});

export const elements = PropTypes.oneOfType([
  PropTypes.element,
  PropTypes.arrayOf(PropTypes.element),
]);

export const isAccountDeactivable = PropTypes.shape({
  isAccountDeactivable: PropTypes.bool.isRequired
});

// USED TO CATCH THE YEAR VALUE GIVEN VIA URL
export const year = PropTypes.shape({
  year: PropTypes.string.isRequired
});

export const modal = PropTypes.shape({
  type: PropTypes.string,
  data: PropTypes.object
});

export const successAnswer = PropTypes.shape({
  redirectUrl: PropTypes.string,
  success: PropTypes.number
});


export const identity = PropTypes.shape({
  photoUrl: PropTypes.string.isRequired,
  nameSurname: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  bio: PropTypes.object.isRequired
});
export const identityList = PropTypes.shape({
  identities: PropTypes.arrayOf(identity)
});


export const indexContents = PropTypes.shape({
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      duration: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  )
});

export const media = PropTypes.shape({
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  sourceName: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
});

export const webinar = PropTypes.shape({
  title: PropTypes.string.isRequired,
  date: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
});

export const keyDate = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string.isRequired,
  date: PropTypes.number.isRequired,
  info: PropTypes.string,
  displayLink: PropTypes.bool.isRequired,
  linkTo: PropTypes.string,
  linkCopy: PropTypes.string,
  placement: PropTypes.string,
  keyDateType: PropTypes.string.isRequired,
});

export const helpfulLink = PropTypes.shape({
  linkTo: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
});

export const helpfulLinks = PropTypes.arrayOf(helpfulLink);

export const businessDetails = PropTypes.shape({
  entityName: PropTypes.string,
  abn: PropTypes.string
});


export const dashboardAlert = PropTypes.shape({
  text: PropTypes.string
});

export const individualTaxReportUrl = PropTypes.shape({
  tax: PropTypes.shape({ url: PropTypes.string.isRequired })
});

export const faqItem = PropTypes.shape({
  question: PropTypes.string,
  answer: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
});

export const faqList = PropTypes.arrayOf(faqItem);
