import classNames from 'classnames';
import BodyText from 'src/design/components/bodyText/BodyText';
import styles from 'src/design/components/bodyText/BodyText.mscss';

const sizeClass = (size) => `bodyText--${size}-line-height`;

const Paragraph = ({ children, textSize, className, testRef, ...props }) => (
  <BodyText
    className={classNames(styles.paragraph, sizeClass(textSize), className)}
    textSize={textSize}
    {...props}
  >
    <p data-test-reference={testRef}>{children}</p>
  </BodyText>
);

export default Paragraph;
