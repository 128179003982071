import { ThemeProvider } from 'styled-components';

const horribleFallbackColor = 'greenyellow';

export const getThemeColor = (color) => (props) =>
  (props.theme.colors && props.theme.colors[color]) || horribleFallbackColor;
export const provideTheme = (WrappedComponent, theme) => (props) => {
  return (
    <ThemeProvider theme={theme}>
      <WrappedComponent {...props} />
    </ThemeProvider>
  );
};

export const getBrandColor = {
  blue: {
    primary: '#3B95EB',
  },
};
