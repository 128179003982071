import styled from 'styled-components';
import { getThemeColor } from 'src/themes/themeUtils';
import { passPropsThrough } from 'src/design/styleguide/common/styledComponentsUtils';
import Text from 'src/design/styleguide/text/Text';

const HeadingCommon = styled(Text).attrs(passPropsThrough({
  align: 'center',
}))`
  margin: 0;
  color: ${getThemeColor('onSurfaceContrast800')};
`;

export const HeroSectionHeading = styled(HeadingCommon).attrs(passPropsThrough({
  as: 'h1',
  fontSize: '2x-small',
  color: 'primary',
  fontWeight: 'bold',
  lineHeight: 'large',
}))`
  letter-spacing: 2px;
`;

export const Heading = styled(HeadingCommon).attrs(passPropsThrough({
  as: 'h1',
  fontSize: 'normal',
  fontWeight: 'bold',
  lineHeight: 'medium',
}))``;

export const SubHeading = styled(HeadingCommon).attrs(passPropsThrough({
  as: 'h2',
  fontSize: 'normal',
  fontWeight: 'bold',
  lineHeight: 'small',
}))``;
