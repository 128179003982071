import { floatToPercentOneOrTwoDecimal } from 'src/utils/formats';
import { getTransactionFeeRatio } from 'src/settings/financials';
import { privacyUrl, termsUrl } from 'src/utils/pageUrls';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import _ from 'lodash';
import PropertyTypes from 'src/enums/propertyTypes';

export const strToTitle = (str) => _.startCase(_.camelCase(str));

export const customerServiceMemberNickname = () => 'the BrickX Team';
export const transactionFeeText = () => floatToPercentOneOrTwoDecimal(getTransactionFeeRatio());
export const identityCheckConsentCheckboxText = () => <span>I consent to the collection, use and disclosure of my personal information in accordance with <Styledlink href={privacyUrl()} target="_blank" testRef="privacy-link">BrickX's Privacy Policy</Styledlink>. I confirm that I have read and accepted the <Styledlink href={termsUrl()} target="_blank" testRef="terms-link">Terms and Conditions</Styledlink> and consent to my personal information being disclosed to a credit reporting agency or my information being checked with the document issuer or official record holder in connection with a request to verify my identity in accordance with the AML/CTF Act. <br/>I confirm that I consent to my personal information being used for the purposes of the issuer assessing whether I am likely to be in the target market for BrickX</span>;
export const minorConsentCheckboxText = () => (
  <span>
    By ticking this box and submitting this application: <br/>
    (a) I declare that I hold the application monies and any resulting interest in the BrickX Platform which is applied for, and all earnings, profits, gains or other additions to that interest, on bare trust for the absolute benefit and enjoyment of the previously named Minor as beneficiary; and <br/>
    (b) I acknowledge that I am making the application in my name as trustee.
  </span>
);
export const propertyCapitalReturnsBoxHistoricalSuburbGrowthDescription = (numberOfYears = 20, dataprovider) => `${numberOfYears} years compound annual, ${dataprovider}`;
export const historicalGrowthDataProvider = (propertyType) => {
  return {
    unit: 'CoreLogic',
    house: 'CoreLogic',
    farm: 'Rural Bank',
    offices: 'Colliers Research',
    multiunit: 'CoreLogic',
    multihouse: 'CoreLogic',
    development: 'CoreLogic',
  }[propertyType];
};

export const historicalGrowthTitle = (propertyType) => {
  return {
    offices: 'HISTORICAL PRECINCT GROWTH'
  }[propertyType] || 'HISTORICAL SUBURB GROWTH';
};

export const historicalGrowthCardTitle = (propertyType) => {
  return {
    offices: <span>Historical<br/>Precinct Growth</span>
  }[propertyType] || <span>Historical<br/>Suburb Growth</span>;
};

export const historicalGrowthChartHeader = (propertyType) => {
  return {
    farm: 'Historical Municipality Performance - Median Price per Hectare',
    offices: 'Historical Precinct Performance - Median Price per m²'
  }[propertyType] || 'Historical Suburb Performance - Median Price';
};

export const historicalGrowthChartSubHeader = (property) => {
  return {
    offices: `${strToTitle(property.historicalGrowthKey)}, ${property.state} (${_.capitalize(property.propertyType)})`,
    multihouse: `${property.suburb}, ${property.state} ${property.state} (Houses)`,
    multiunit: `${property.suburb}, ${property.state} ${property.state} (Units)`,
  }[property.propertyType] || `${property.suburb}, ${property.state} (${_.capitalize(property.propertyType)}s)`;
};

export const historicalGrowthChartBottom = (propertyType) => {
  return {
    unit: 'SA2 Suburb Index for the median price of all sales (excluding multi-sales) for rolling 12 months. Source: CoreLogic',
    house: 'SA2 Suburb Index for the median price of all sales (excluding multi-sales) for rolling 12 months. Source: CoreLogic',
    multiunit: 'SA2 Suburb Index for the median price of all sales (excluding multi-sales) for rolling 12 months. Source: CoreLogic',
    multihouse: 'SA2 Suburb Index for the median price of all sales (excluding multi-sales) for rolling 12 months. Source: CoreLogic',
    farm: 'Median price per hectare for farmland property sales (excluding farms under 30ha, compulsory acquisitions and related party transactions. Source: Rural Bank Australia Farmland Values',
    offices: 'Yearly median price per m2 for office sales over $5M. Source: Colliers Research'
  }[propertyType];
};

// --+-- Pop Opver Text --+--
export const historicalSuburbGrowthPopoverText = (propertyType, numberOfYearsInteger, yearsOrYear) => {
  return {
    unit: `The compound annual growth rate of the SA2 Suburb Index over the last ${numberOfYearsInteger} ${yearsOrYear} (Source: CoreLogic).`,
    house: `The compound annual growth rate of the SA2 Suburb Index over the last ${numberOfYearsInteger} ${yearsOrYear} (Source: CoreLogic).`,
    multiunit: `The compound annual growth rate of the SA2 Suburb Index over the last ${numberOfYearsInteger} ${yearsOrYear} (Source: CoreLogic).`,
    multihouse: `The compound annual growth rate of the SA2 Suburb Index over the last ${numberOfYearsInteger} ${yearsOrYear} (Source: CoreLogic).`,
    farm: `The compound annual growth rate of the SA2 Suburb Index over the last ${numberOfYearsInteger} ${yearsOrYear} (Source: RuralBank).`,
    offices: `The compound annual growth rate of the yearly median price per m2 for office sales over $5M over the last ${numberOfYearsInteger} years. (Source: Colliers Research)`
  }[propertyType] || '';
};

export const getPropertyValuePopoverText = (propertyType, numberOfYears) => {
  return {
    unit: `This number is currently defaulted to the compound annual growth rate of the SA2 Suburb Index over the last ${numberOfYears} years (Source: CoreLogic)`,
    house: `This number is currently defaulted to the compound annual growth rate of the SA2 Suburb Index over the last ${numberOfYears} years (Source: CoreLogic)`,
    multiunit: `This number is currently defaulted to the compound annual growth rate of the SA2 Suburb Index over the last ${numberOfYears} years (Source: CoreLogic)`,
    multihouse: `This number is currently defaulted to the compound annual growth rate of the SA2 Suburb Index over the last ${numberOfYears} years (Source: CoreLogic)`,
    farm: `This number is currently defaulted to the compound annual growth rate of the SA2 Suburb Index over the last ${numberOfYears} years (Source: RuralBank)`,
    offices: 'This number is currently defaulted to the compound annual growth rate of the yearly median price per m2 for office sales over $5M over the last 10 years. (Source: Colliers Research)'
  }[propertyType] || '';
};

export const getGearingEffectPopoverText = (propertyType) => {
  return {
    multiunit: `The 'Gearing effect' can be added to/subtracted from the 'historical suburb growth' figure to estimate the effects of property debt as values grow or decline.`,
    multihouse: `The 'Gearing effect' can be added to/subtracted from the 'historical suburb growth' figure to estimate the effects of property debt as values grow or decline.`
  }[propertyType] || `The 'Gearing effect' can be added to/subtracted from the 'historical suburb growth' figure to estimate the effects of having a mortgage on the property as its value grows or declines.`;
};

export const getGrossRentalIncomePopoverText = (propertyType) => {
  return {
    multiunit: `The annual rental income expected from this investment, assuming full occupancy during the year.`,
    multihouse: `The annual rental income expected from this investment, assuming full occupancy during the year.`
  }[propertyType] || `The annual rental income expected from the property, assuming full occupancy during the year.`;
};

export const getPropertyExpensesPopoverText = (propertyType) => {
  return {
    multiunit: `The annual expenses expected from this investment this year, including water, maintenance, property management fees, strata & more`,
    multihouse: `The annual expenses expected from this investment this year, including water, maintenance, property management fees, strata & more`
  }[propertyType] || `The annual expenses expected from the property this year, including water, maintenance, property management fee, strata & more. See pie chart below for full details.`;
};

export const getInterestPaymentsPopoverText = (propertyType) => {
  return {
    multiunit: `If this investment has debt, the annual interest payments (paid monthly).`,
    multihouse: `If this investment has debt, the annual interest payments (paid monthly).`
  }[propertyType] || `If the property has debt, the annual interest payments on the mortgage (paid monthly).`;
};

export const getLatestPropertyValuationPopoverText = (propertyType, isPreOrderOrNotIndependentValuedProperty) => {
  if (isPreOrderOrNotIndependentValuedProperty) {
    return {
      multiunit: `The market value, paid to purchase the properties.`,
      multihouse: `The market value, paid to purchase the properties.`
    }[propertyType] || `The market value, paid to purchase the property.`;
  }
  return {
    multiunit: `The latest Independent Valuation of the properties.`,
    multihouse: `The latest Independent Valuation of the properties.`
  }[propertyType] || `The latest Independent Valuation of this property.`;
};

export const getCashReservePopoverText = (propertyType) => {
  return {
    multiunit: `Money kept primarily for funding unexpected expenses and to cover bills during periods of vacancy`,
    multihouse: `Money kept primarily for funding unexpected expenses and to cover bills during periods of vacancy`
  }[propertyType] || `Money kept primarily for funding unexpected expenses and to cover bills during void periods at the property.`;
};

export const getLatestValuePopoverText = (propertyType, isPreOrderOrNotIndependentValuedProperty) => {
  if (isPreOrderOrNotIndependentValuedProperty) {
    return {
      multiunit: `The sum of the assets in this investment, including the Purchase Cost, Acquisition Costs and Cash Reserve`,
      multihouse: `The sum of the assets in this investment, including the Purchase Cost, Acquisition Costs and Cash Reserve`
    }[propertyType] || `The sum of the assets in this property, including the Purchase Cost, Acquisition Costs, and Cash Reserve.`;
  }
  return {
    multiunit: `A sum of the assets in this investment, including the latest Independent Valuation of the properties, unamortised Acquisition Costs, and Cash Reserve.`,
    multihouse: `A sum of the assets in this investment, including the latest Independent Valuation of the properties, unamortised Acquisition Costs, and Cash Reserve.`
  }[propertyType] || `A sum of the assets in this property, including the latest Independent Valuation of the property, unamortised Acquisition Costs, and Cash Reserve.`;
};

export const getEquityPopoverText = (propertyType) => {
  return {
    multiunit: `The remaining value in the assets, after debt, which drives ‘Brick Valuation`,
    multihouse: `The remaining value in the assets, after debt, which drives ‘Brick Valuation`
  }[propertyType] || `The remaining value in the property, after Debt, which drives 'Brick' Valuation.`;
};

export const arrangementFeeProperties = (proeprtyCode) => {
  if ([
    'ENM01',
    'MOS01',
    'PRA01',
    'ANN01',
    'DBB01',
    'BND01',
    'PML01',
    'DAR01',
    'SUR01',
    'PPT01',
    'MAN01',
    'BRW01',
    'STP01',
    'BLM01',
    'DAR02',
    'KIN01',
    'SLD01',
    'BAN01',
    'KGT01',
    'CLN01',
    'TAR01',
    'WYN01',
    'CLN02',
    'CLN03',
    'NML01',
  ].includes(proeprtyCode)) {
    return 'Arrangement Fees';
  }
  return 'Due Diligence Fee';
};


export const propertyTypeFormatter = (propertyType) => {
  const naming = {
    unit: 'Unit',
    house: 'House',
    farm: 'Farm',
    offices: 'Offices',
    multiunit: 'Multi Unit',
    multihouse: 'Multi House',
    [PropertyTypes.development]: 'Development'
  };
  return naming[propertyType] || propertyType;
};
