const addTabIdQuery = (url, tabId) => `${url}${tabId ? `?activeTabId=${tabId}` : ''}`;
const addScrollToQuery = (url, scrollToId) => `${url}${scrollToId ? `?scrollTo=${scrollToId}` : ''}`;

export const onboardingTmdUrl = () => '/account/auto-invest/onboarding/tmd';
export const onboardingQuestionsUrl = () => '/account/auto-invest/onboarding/questions';
export const onboardingProductSelectionUrl = () => '/account/auto-invest/onboarding/pick-a-plan';
export const onboardingSmartInvestDetailsUrl = () => '/account/auto-invest/onboarding/details';
export const accountUrl = () => '/account';
export const dashboardUrl = () => '/account/home';
export const portfolioUrl = (tabId) => addTabIdQuery('/account/portfolio', tabId);
export const portfolioDetailsUrl = () => '/account/portfolio/details';
export const portfolioPropertyUrl = (planType, propertyCode) => `/account/portfolio/${planType}/${propertyCode}`;
export const sellBricksUrl = () => '/account/portfolio/sell-bricks';
export const settingsUrl = (tabId) => addTabIdQuery('/account/settings', tabId);
export const settingsManageUrl = () => '/account/settings/manage-si';
export const settingsDisableUrl = () => '/account/settings/disable-si';
export const settingsEnableUrl = () => '/account/settings/enable-si';
export const settingsSkipAMonthUrl = () => '/account/settings/skip-a-month';
export const referralsUrl = () => '/account/referrals';
export const depositUrl = () => '/account/deposit';
export const transactionsUrl = () => '/account/transactions';
export const documentsUrl = () => '/account/documents';
export const tradeConfirmationDocumentsUrl = () => '/account/documents/trade-confirmation';
export const distributionStatementUrl = () => '/account/documents/distribution-statement';
export const pendingOrdersUrl = () => '/account/pending-orders';
export const withdrawUrl = () => '/account/withdraw';
export const walletUrl = () => '/account/wallet';
export const priceAlertsUrl = () => '/account/price-alerts';
export const onboardingUrl = () => '/onboarding';
export const newsUrl = () => '//news.brickx.com';
export const RG46ScheduleUrl = () => '/pds/latest-pds-updates';
export const notFoundUrl = () => '/404';
export const homeUrl = () => '/';
export const homeFeesUrl = () => addScrollToQuery('/', 'about-the-fees');
export const howItWorksUrl = (tabId) => addTabIdQuery('/how-it-works', tabId);
export const smartInvestUrl = () => '/smart-invest';
export const howItWorksSmartInvestTabUrl = () => howItWorksUrl('0');
export const howItWorksBuildMyOwnTabUrl = () => howItWorksUrl('1');
export const contactUrl = () => '/contact';
export const bookACallUrl = (source) => `//brickx.youcanbook.me${source ? `?SOURCE=${source}` : ''}`;
export const complaintsUrl = () => '/complaints';
export const aboutUrl = () => '/about';
export const pdsUrl = () => '/pds';
export const pdsDirectUrl = () => 'https://assets.brickx.com/downloads/BrickX PDS - 25 August 2023.pdf';
// export const significantEventNoticeUrl = () => 'https://assets.brickx.com/downloads/BrickX%20Significant%20Event%20Notice%2020.10.21.pdf';
export const significantEventNoticeUrl = () => 'https://assets.brickx.com/downloads/BrickX _ Significant event notice (SEN) - 25 August 2023 final.pdf';
export const additionalPdsCLN01DirectUrl = () => 'https://assets.brickx.com/downloads/Additional+Disclosure+Document+16.10.20.pdf';
export const additionalPdsTAR01DirectUrl = () => 'https://assets.brickx.com/downloads/Additional+Disclosure+Document+-+TAR01+19112020+Final.pdf';
export const fsgDirectUrl = () => 'https://assets.brickx.com/downloads/BrickX Financial Services Guide - Final approved for release 3 May 2023.pdf';
export const tmdUrl = () => 'https://assets.brickx.com/downloads/Target+Market+Determination+-+BrickX+Platform+v2.pdf';
export const sqmReportDirectUrl = () => 'https://assets.brickx.com/downloads/BRICKX_Trusts.pdf';
export const rentToBuyFlyerUrl = () => 'https://assets.brickx.com/downloads/avenue-flyer-13-11-2019.pdf';
export const returnsCalculatorUrl = () => '/returns-calculator';
export const mediaEnquiriesUrl = () => '/media-enquiries';
export const mediaUrl = () => '/media';
export const loginUrl = () => '/login';
export const logoutUrl = () => '/logout';
export const signupUrl = () => '/signup';
export const signupMinorUrl = () => '/signup/minor';
export const signupSmsfUrl = () => '/signup/smsf';
export const switchAccountUrl = () => '/switch-account';
export const loggedOutUrl = () => '/logged-out';
export const acceptPdsUrl = () => '/accept-pds';
export const identityVerificationUrl = () => '/setup';
export const vedaFailUrl = () => '/veda';
export const boVedaFailedUrl = () => '/bo-veda';
export const smsfAndTrustInReviewUrl = () => '/smsf-and-trust-application-in-review';
export const propertyTeamUrl = () => '/team';
export const jobsUrl = () => '//jobs.brickx.com';
export const resetPasswordUrl = () => '/reset-password';
export const changePasswordUrl = () => '/account/password';
export const propertiesUrl = () => '/properties';
export const farmsUrl = () => '/farms';
export const propertyUrl = (propertyCode) => `/properties/${propertyCode}/summary`;
export const reChangeInfoPageUrl = () => '/members-meeting';

export const helpCenterUrl = () => '//help.brickx.com';
export const helpCenterDifferenceBetweenSiAndBmoArticleUrl = () => '//brickxx.freshdesk.com/support/solutions/articles/17000077272-smart-invest-vs-build-my-own-what-s-the-difference-';
export const feesAndTaxesHelpUrl = () => '//help.brickx.com/support/solutions/articles/17000077301-how-do-brickx-fees-work';
export const benefitsAndRisksUrl = () => '//help.brickx.com/support/solutions/articles/17000079800-what-are-the-benefits-and-risks-of-investing-in-bricks-';
export const potentialReturnsHelpUrl = () => '//help.brickx.com/support/solutions/17000081352';
export const whatReportsWillIRecieveUrl = () => '//help.brickx.com/support/solutions/articles/17000074559-what-reports-will-i-receive-';
export const brickXStructureUrl = () => '//help.brickx.com/support/solutions/articles/17000077292-who-operates-manages-and-holds-assets-for-the-brickx-platform-';
export const preOrderHelpUrl = () => '//help.brickx.com/support/solutions/articles/17000077063-user-guide-how-does-pre-order-work-';
export const aboveBelowValuationHelpUrl = () => '//help.brickx.com/support/solutions/articles/17000074810-user-guide-what-does-above-and-below-valuation-mean-';
export const feeChangeCeoLetterNewsArticleUrl = () => '//news.brickx.com/announcements/transaction-fees-update/';
export const whatIsGearingEffectArticleUrl = () => '//help.brickx.com/support/solutions/articles/17000094932-what-is-the-gearing-effect-';

// @TODO: I believe the plan is to gradually move all of the below terms pages over to fresh-desk,
// so that marketing/customer-service/compliance are in direct control of them...
export const termsUrl = () => '/terms';
export const raizReferralTermsUrl = () => '/terms/partner-referrals/raiz';
export const feesPromo2018TermsUrl = () => '/terms/fees-promo-2018';
export const noFeesPreOrderKGT012019TermsUrl = () => '/terms/no-fees-pre-order-KGT01-2019';
export const april2019FeesPromoTermsUrl = () => '//help.brickx.com/support/solutions/articles/17000087968-april2019feespromoterms';
export const may2019FeesPromoTermsUrl = () => '//help.brickx.com/support/solutions/articles/17000089069-0-5-fee-promotion-may-2019-';
export const privacyUrl = () => '/privacy';
export const returnsCalculatorDisclosuresUrl = () => '/terms/returns-calculator-disclosures';

export const referrerPageUrl = () => '/account/referrals';
export const refereePageUrl = () => `/referrals`;
export const refereeNotEligiblePageUrl = () => '/referee-not-eligible';
export const referralTermsUrl = () => '/terms/referrals';

export const smsfUrl = () => '/smsf';
export const smsfSuccessUrl = () => '/smsf-success';

export const emailVerificationUrl = (token) => `/verify${token ? `/${token}` : ''}`;

export const rentToBuyUrl = () => '/smart-renter';
export const loyaltyBonusAnnouncementUrl = () => 'https://news.brickx.com/announcements/its-never-too-late-for-romance/';
export const propertyVoteUrl = (propertyCode) => ({
  ENM01: 'https://news.brickx.com/property/notice-of-meeting-brickx-trust-no-1-enmore',
  MOS01: 'https://news.brickx.com/property/notice-of-meeting-brickx-trust-no-2-mosman',
  PRA01: 'https://news.brickx.com/property/notice-of-meeting-brickx-trust-no-3-prahran',
  ANN01: 'https://news.brickx.com/property/notice-of-meeting-brickx-trust-no-4-annandale',
  BLM01: 'https://news.brickx.com/property/notice-of-meeting-brickx-trust-no-14-balmain',
  PML01: 'https://news.brickx.com/property/notice-of-meeting-brickx-trust-no-7-port-melbourne',
  DBB01: 'https://news.brickx.com/property/notice-of-meeting-brickx-trust-no-5-double-bay',
  BND01: 'https://news.brickx.com/property/notice-of-meeting-brickx-trust-no-6-bondi',
  SUR01: 'https://news.brickx.com/property/notice-of-meeting-brickx-trust-no-10-surry-hills',
  DAR01: 'https://news.brickx.com/property/notice-of-meeting-brickx-trust-no-8-darlinghurst',
  BRW01: 'https://news.brickx.com/property/notice-of-meeting-brickx-trust-no-11-brunswick-west',
  PPT01: 'https://news.brickx.com/property/notice-of-meeting-brickx-trust-no-9-potts-point', // added 15 Aug 2022
  MAN01: 'https://news.brickx.com/property/notice-of-meeting-brickx-trust-no-12-manly', // added 15 Aug 2022
  STP01: 'https://news.brickx.com/property/notice-of-meeting-brickx-trust-no-13-st-peters', // added 15 Aug 2022
})[propertyCode] || 'https://news.brickx.com/property/five-year-anniversary-of-enmore-and-mosman-properties/';

// Landing page
export const minorLandingPageUrl = () => '/minors';
export const wholesaleLandingPageUrl = () => '/professional-and-sophisticated';
export const privateSyndicationLandingPageUrl = () => '/private-syndication';
export const brickxDevelopmentLandingPageUrl = () => '/brickx-developments';

export const detailsUpdateUrl = () => '/account/update-details';
export const addressUpdateUrl = () => '/account/update-details?mode=0';
export const mobileNumberUpdateUrl = () => '/account/update-details?mode=1';
export const emailUpdateUrl = () => '/account/update-details?mode=2';
export const bankDetailsUpdateUrl = () => '/account/update-details?mode=3';


// public asset url
export const getFullAssetUrl = (path) => `https://assets.brickx.com${path}`;
export const getFullImageAssetUrl = (path) => `https://assets.brickx.com/images${path}`;
