import { Component as ReactComponent } from 'react';

const withState = ({
  name = 'WithState',
  Component,
  componentDidMount,
  componentWillReceiveProps,
  componentDidUpdate,
  componentWillUnmount,
  mapPropsToInitialState = () => ({}),
}) => {
  return class WithState extends ReactComponent {
    static get name() {
      return name;
    }

    state = {
      ...mapPropsToInitialState(this.props),
    };

    componentDidMount() {
      componentDidMount &&
        componentDidMount(
          this.props,
          this.state,
          (values) => this.setState(values),
          { setOnThis: this.setOnThis, getFromThis: this.getFromThis }
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      componentWillReceiveProps &&
        componentWillReceiveProps(this.props, nextProps);
    }

    componentDidUpdate(prevProps, prevState) {
      componentDidUpdate &&
        componentDidUpdate(this.props, this.state, prevProps, prevState);
    }

    componentWillUnmount() {
      componentWillUnmount &&
        componentWillUnmount(this.props, this.state, {
          setOnThis: this.setOnThis,
          getFromThis: this.getFromThis,
        });
    }

    setOnThis = (key, value) => {
      this[key] = value;
    };

    getFromThis = (key) => {
      return this[key];
    };

    render() {
      return (
        <Component
          {...this.props}
          setState={(values) => this.setState(values)}
          state={this.state}
        />
      );
    }
  };
};

export default withState;
