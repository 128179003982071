import classNames from 'classnames';
import styled from 'styled-components';
import { getThemeColor } from 'src/themes/themeUtils';
import { lighten } from 'src/design/styleguide/common/sassFunctions';
import Button, {
  colors,
  getColor,
  sizes
} from 'src/design/components/button/Button';

const getSelectedColor = (props) => getThemeColor(getColor(props.color))(props);

const StyledButton = styled(({ asElement, ...props }) => <Button {...props} as={asElement}/>)`
  && {
    background-color: ${getSelectedColor};
    color: white;
  }

  &&[disabled] {
    background-color: ${getThemeColor('concrete')};
  }

  &&:hover {
    color: white;
  }

  &&:active {
    color: white;
    background-color: ${props => lighten(getSelectedColor(props), 10)};
    transition-property: transform, box-shadow;
  }
`;

const PrimaryButton = ({ className, children, as, ...props }) => (
  <StyledButton
    className={classNames(className)}
    asElement={as}
    {...props}
  >{children}</StyledButton>
);

PrimaryButton.colors = colors;
PrimaryButton.sizes = sizes;

export default PrimaryButton;
