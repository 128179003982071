export const renderCode = (propertyCode) => {
  // use this only for properties that needs to update propery code, e.g. ADI01 -> ADP01
  //
  // Given the limitation of backend it is infeasible to change the code however due to
  // some reason such as break of regulation we may need to do it.
  const propertyCodeMask = {
    ADI01: 'ADP01',
  };
  return propertyCodeMask[propertyCode] || propertyCode;
};

/** HARD-CODED APIR code */
export const apirCode = {
  ANN01: 'BFS0167AU',
  HAW01: 'BFS0424AU',
  SLD01: 'BFS0987AU',
  BND01: 'BFS1160AU',
  DAR02: 'BFS1163AU',
  NEW01: 'BFS1278AU',
  ADI01: 'BFS2224AU',
  CNG01: 'BFS2346AU',
  NML01: 'BFS2887AU',
  BAN01: 'BFS3085AU',
  PPT01: 'BFS3289AU',
  KGT01: 'BFS3374AU',
  SOM01: 'BFS4578AU',
  STP01: 'BFS5169AU',
  SSL01: 'BFS5208AU',
  LCF01: 'BFS5651AU',
  MOS01: 'BFS6078AU',
  BDF01: 'BFS6249AU',
  BRW01: 'BFS6336AU',
  DBB01: 'BFS6477AU',
  KAN01: 'BFS6753AU',
  DAR01: 'BFS7100AU',
  ARN01: 'BFS7688AU',
  WRF01: 'BFS8420AU',
  PRA01: 'BFS8423AU',
  ENM01: 'BFS8831AU',
  MAN01: 'BFS9074AU',
  SUR01: 'BFS9095AU',
  KIN01: 'BFS9209AU',
};

/** HARD-CODED voting date */
export const votingDate = {
  ENM01: 'May 2025',
  MOS01: 'May 2025',
  PRA01: 'July 2026',
  ANN01: 'July 2026',
  DBB01: 'March 2027',
  BND01: 'March 2027',
  DAR01: 'July 2027',
  PPT01: 'September 2027',
  SUR01: 'July 2027',
  MAN01: 'September 2027',
  STP01: 'September 2027',
  DAR02: 'June 2028',
  KIN01: 'November 2028',
  SLD01: 'November 2028',
  BAN01: 'November 2028',
  KGT01: 'June 2029',
  ARN01: 'November 2026',
  HAW01: 'July 2027',
  KAN01: 'November 2027',
  NML01: 'June 2026',
  SOM01: 'November 2027',
  CNG01: 'October 2026',
  WRF01: 'March 2029',
  BDF01: 'June 2029',
};
