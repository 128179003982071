import numeral from 'numeral';
import moment from 'moment-timezone';
import escapeTextContentForBrowser from 'react-lib-escape-text-content-for-browser';
import DateTime from 'scripts/constants/DateTime';
import { renderCode } from 'src/settings/properties';

const formatters = {
  clean() {
    return numeral(this).value();
  },
  countryCodePhoneNumber() {
    let number = this;
    if (number.toString().includes('+')) {
      return number;
    }
    if (number[0] === '0') {
      number = number.substring(1);
    }
    return `+61${number}`;
  },
  brickPrice() {
    return this::formatters.dollarDecimal();
  },
  dollar(value) {
    return numeral(value || this).format('$0,0');
  },
  dollarAbbreviated(value) {
    return numeral(value || this).format('$0.0a');
  },
  dollarDecimal(value) {
    return numeral(value || this).format('$0,0.00');
  },
  threeDollarDecimal() {
    return numeral(this).format('$0,0.000');
  },
  number() {
    return numeral(this).format('0,0');
  },
  priceWithNoDecimalsRounded() {
    return numeral(this).format('$0,0');
  },
  priceWithNoDecimalsFloored() {
    return numeral(this).format('$0,0', Math.floor);
  },
  decimals() {
    return numeral(this).format('.00');
  },
  numberDecimal() {
    return numeral(this).format('0,0.00');
  },
  percent() {
    return numeral(this).format('0%');
  },
  percentDecimal(value) {
    return numeral(value || this).format('0.00%');
  },
  percentDecimalWithSign(value) {
    return numeral(value || this).format('+0.00%');
  },
  percentDecimalWithMinusSign(value) {
    return numeral(value || this).format('-0.00%');
  },
  dayMonthCommaYearExtended() {
    return moment(this).tz(DateTime.TIME_ZONE).format('DD MMMM, YYYY');
  },
  dayMonthCommaYearAndTimeExtendedWithTimeZone() {
    return moment(this)
      .tz(DateTime.TIME_ZONE)
      .format('Do MMMM, YYYY [at] hh:mm a z');
  },
  monthCommaYearExtended() {
    return moment(this).tz(DateTime.TIME_ZONE).format('MMMM, YYYY');
  },
  dayMonthYearExtended() {
    return moment(this).tz(DateTime.TIME_ZONE).format('DD MMMM YYYY');
  },
  monthYearExtended() {
    return moment(this).tz(DateTime.TIME_ZONE).format('MMMM YYYY');
  },
  monthYear() {
    return moment(this).tz(DateTime.TIME_ZONE).format('MMM YYYY');
  },
  monthYearShorten() {
    return moment(this).tz(DateTime.TIME_ZONE).format("MMM [']YY");
  },
  monthYearShort() {
    return moment(this).tz(DateTime.TIME_ZONE).format('MMM-YY');
  },
  dayMonthAndShortYearNoDash() {
    return moment(this).tz(DateTime.TIME_ZONE).format("DD MMM [']YY");
  },
  nextMonthYear() {
    return moment(this)
      .add(1, 'months')
      .tz(DateTime.TIME_ZONE)
      .format('MMM YYYY');
  },
  year() {
    return moment(this).tz(DateTime.TIME_ZONE).format('YYYY');
  },
  month() {
    return moment(this).tz(DateTime.TIME_ZONE).format('MMM');
  },
  dateWithSlash() {
    return moment(this).tz(DateTime.TIME_ZONE).format('DD/MM/YYYY');
  },
  dateWithSlashUS() {
    return moment(this).tz(DateTime.TIME_ZONE).format('MM/DD/YYYY hh:mm:s');
  },
  dayAndExtendedMonth() {
    return moment(this).tz(DateTime.TIME_ZONE).format('DD MMM');
  },
  timeWithTimeZoneShort() {
    return moment(this).tz(DateTime.TIME_ZONE).format('hh A z');
  },
  timeWithTimeZone() {
    return moment(this).tz(DateTime.TIME_ZONE).format('hh:mm A z');
  },
  date() {
    return moment(this).tz(DateTime.TIME_ZONE).format('DD MMM YYYY');
  },
  dateTime() {
    return moment(this)
      .tz(DateTime.TIME_ZONE)
      .format(DateTime.DATE_TIME_FORMAT);
  },
  dateHalfExtended() {
    return moment(this).tz(DateTime.TIME_ZONE).format('Do MMM YYYY');
  },
  daysTillNow() {
    const today = moment().tz(DateTime.TIME_ZONE).startOf('day');
    const givenTime = moment(this).tz(DateTime.TIME_ZONE).startOf('day');
    return today.diff(givenTime, 'days') + 1;
  },
  monthsTillNow() {
    const today = moment().tz(DateTime.TIME_ZONE).startOf('month');
    const givenTime = moment(this).tz(DateTime.TIME_ZONE).startOf('month');
    return today.diff(givenTime, 'months');
  },
  daysUntil() {
    const today = moment().tz(DateTime.TIME_ZONE).startOf('day');
    const givenTime = moment(this).tz(DateTime.TIME_ZONE).startOf('day');
    return givenTime.diff(today, 'days');
  },
  today() {
    return moment().tz(DateTime.TIME_ZONE).format('DD MMM YYYY');
  },
  isInLastMonth() {
    const today = moment().tz(DateTime.TIME_ZONE);
    const daysOfMonth = 30;
    const aMonthAgo = moment()
      .tz(DateTime.TIME_ZONE)
      .subtract(daysOfMonth, 'day');
    const givenDateTime = moment(this).tz(DateTime.TIME_ZONE);
    return givenDateTime.isBetween(aMonthAgo, today, null, '[]');
  },
  isToday() {
    const today = moment().tz(DateTime.TIME_ZONE).startOf('day');
    const givenTime = moment(this).tz(DateTime.TIME_ZONE).startOf('day');
    return givenTime.diff(today, 'days') === 0;
  },
  now() {
    return moment().tz(DateTime.TIME_ZONE).format(DateTime.DATE_TIME_FORMAT);
  },
  months() {
    let pluralisedText = '';
    if (this > 1) pluralisedText = `${this} months`;
    else pluralisedText = `${this} month`;
    return pluralisedText;
  },
  minute() {
    var minute = '';
    if (this > 1) minute = `${this} minutes`;
    else minute = `${this} minute`;
    return minute;
  },
  order() {
    const { orderSide, quantity, propertyCode, price } = this;
    return `${orderSide::formatters.capitalize()} ${quantity
      ::formatters.number()
      ::formatters.brick()} in ${renderCode(
      propertyCode
    )} at price ${price::formatters.dollarDecimal()}`;
  },
  purchaseProposal() {
    const { quantity, propertyCode, basisPrice } = this;
    return `buy ${quantity
      ::formatters.number()
      ::formatters.brick()} in ${renderCode(
      propertyCode
    )} @ ${basisPrice::formatters.number()}`;
  },
  brick() {
    var brick = '';
    if (this > 1) brick = `${this} Bricks`;
    else brick = `${this} Brick`;
    return brick;
  },
  millSecondsToMinutesAndHours() {
    const millsInHour = 3600000;
    const millsInMinute = 60000;
    var result = '';
    if (this !== 0) {
      if (this < millsInHour) {
        const minutes = Math.floor(this / millsInMinute);
        if (minutes <= 1) {
          result = `1 minute`;
        } else {
          result = `${minutes} minutes`;
        }
      } else {
        const remainder = this % millsInHour;
        const minutes = Math.floor(remainder / millsInMinute);
        const hours = (this - remainder) / millsInHour;
        if (remainder === 0) {
          result = `${hours}h 0m`;
        } else if (minutes <= 1) {
          result = `${hours}h 1m`;
        } else {
          result = `${hours}h ${minutes}m`;
        }
      }
    }
    return result;
  },
  investor() {
    return numeral(this).format('0,0');
  },
  uuid() {
    return (this || '').substring(24);
  },
  htmlEncoded() {
    return escapeTextContentForBrowser(this);
  },
  capitalize() {
    const value = this || '';
    return value.charAt(0).toUpperCase() + value.slice(1);
  },
  roundPercentDecimal() {
    return Math.round(this * 100) / 100;
  },
  normalizePercent() {
    return this * 100;
  },
  normalizedPercentDecimal() {
    return numeral(this * 100).format('0,0.0');
  },
  dividePercent() {
    return this / 100;
  },
  brickFloor() {
    return Math.floor(this);
  },
  suburb() {
    return this.split(',')[1];
  },
  street() {
    return this.split(',')[0];
  },
  propertyName({ suburb, state, propertyCode }) {
    return `${suburb} ${state} (${renderCode(propertyCode)})`;
  },
};

export default formatters;
