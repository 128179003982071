import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';
import {
  addPersonData as addPersonDataToRollbar,
  error as rollBarError,
} from 'scripts/analytics/rollbar';
import {
  loggedIn as authLoggedIn,
  loggedOut as authLoggedOut,
} from 'scripts/redux/actions/auth';
import {
  beginSession,
  clearSession,
  getCurrentUserId,
} from 'scripts/auth/session';
import { clearReferral } from 'scripts/utilities/referralHelper';
import {
  errorAlert,
  successAlert,
  closeAlert,
} from 'scripts/redux/actions/alerts';
import { getMemberStatus } from 'scripts/redux/actions/member';
import { isUserLoggedIn } from 'scripts/utilities/userAccountHelper';
import { isXmasAndNYHoliday } from 'scripts/utilities/helpers';
import {
  sendLoginSegmentEvents,
  sendLogoutSegmentEvents,
  sendRefreshSessionSegmentEvents,
} from 'scripts/redux/actions/segment/events/loginEvents';
import { settingsUrl, loggedOutUrl } from 'src/utils/pageUrls';
import { successAnswer } from 'scripts/redux/actions/successAnswer';
import User from 'scripts/models/User';
import UserService from 'scripts/services/UserService';
import history from 'src/browser/history';
import Storage from 'scripts/utilities/Storage';

const LOGOUT = 'scripts/user/LOGOUT';
const GET_DETAILS = 'scripts/user/GET_DETAILS';

const logoutAction = createAction(LOGOUT);
const getDetailsAction = createAction(GET_DETAILS);

const storage = new Storage(localStorage);

export function login(data) {
  return async function (dispatch) {
    try {
      addPersonDataToRollbar({
        id: data.email,
        email: data.email,
        username: `${data.email} (login attempt)`,
      });
      const loggedInResponse = await UserService.login(data);
      storage.saveAccountDetails(loggedInResponse.user);
      addPersonDataToRollbar({
        id: loggedInResponse.email,
        email: loggedInResponse.email,
        username: `${loggedInResponse.email} (${loggedInResponse.givenName} ${loggedInResponse.familyName})`,
      });
      await dispatch(setupUserSession(loggedInResponse));
      dispatch(authLoggedIn({ success: true, user: loggedInResponse.user }));
      dispatch(fetchUserDetails());
      sendLoginSegmentEvents(loggedInResponse.user);
      clearReferral();
    } catch (e) {
      dispatch(authLoggedIn({ success: false }));
      dispatch(closeAlert(0));
      dispatch(errorAlert(e.message));
    }
  };
}

export function setupUserSession({ user, authKey }) {
  return async function (dispatch) {
    try {
      const userId = user.id;
      await beginSession(userId, authKey, user.email);
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function refreshUserSession(data) {
  return async function (dispatch) {
    const currentUserId = await getCurrentUserId();
    try {
      if (!_.isNil(currentUserId)) {
        const refreshSessionResponse = await UserService.refreshSession(
          currentUserId,
          data
        );
        await dispatch(setupUserSession(refreshSessionResponse));
        sendRefreshSessionSegmentEvents(refreshSessionResponse.user);
        dispatch(successAlert('Your session has been extended.'));
      }
    } catch (e) {
      dispatch(clearUserSession());
      dispatch(errorAlert(e.message));
    }
  };
}

export function logout() {
  return async function (dispatch) {
    try {
      dispatch(clearUserSession());
      dispatch(authLoggedOut({ success: true }));
      sendLogoutSegmentEvents();
    } catch (e) {
      dispatch(authLoggedOut({ success: false }));
      dispatch(errorAlert(e.message));
    }
  };
}

export function logoutAndRedirectToTimedOutPage() {
  return async function (dispatch) {
    try {
      await dispatch(logout());
      history.replace(loggedOutUrl());
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

function clearUserSession() {
  return async function (dispatch) {
    try {
      await clearSession();
      dispatch(logoutAction());
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function fetchUserDetails(providedUserId) {
  return async function (dispatch) {
    const userId = providedUserId || (await getCurrentUserId());
    if (_.isNil(userId)) {
      return null;
    }
    return Promise.all([
      UserService.getUserDetails(userId),
      UserService.getFinancialDetails(userId),
      UserService.getUserEmailSubscriptions(userId),
    ])
      .then(([userPayload, financialPayload, emailSubscriptionsPayload]) => {
        const payload = {
          ...userPayload,
          ...{
            financialDetails: financialPayload,
            emailSubscriptions: emailSubscriptionsPayload,
          },
        };
        return Promise.all([
          dispatch(getDetailsAction(payload)),
          dispatch(getMemberStatus(userId)),
        ]);
      })
      .catch(() => {});
  };
}

export function deactivateAccount() {
  return async function (dispatch, getState) {
    const { user } = getState();
    try {
      if (isUserLoggedIn(user)) {
        await UserService.deactivateAccount(user.id);
        dispatch(fetchUserDetails());
        dispatch(successAlert('Your account have been deactivated.'));
      }
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function updateUserDetails(details) {
  return async function (dispatch, getState) {
    const { user } = getState();
    try {
      if (isUserLoggedIn(user)) {
        await UserService.updateUserDetails(user.id, details);
        dispatch(fetchUserDetails());
        dispatch(successAlert('Your user details have been updated.'));
      }
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function saveTfnDetails(details) {
  return async function (dispatch, getState) {
    const { user } = getState();
    try {
      if (isUserLoggedIn(user)) {
        await UserService.saveTfnDetails(user.id, details);
        dispatch(fetchUserDetails());
        dispatch(successAlert('Your TFN details have been updated.'));
      }
    } catch (e) {
      dispatch(errorAlert('Failed to save TFN.'));
    }
  };
}

export function saveBankDetails(details) {
  return async function (dispatch, getState) {
    const { user } = getState();
    try {
      if (isUserLoggedIn(user)) {
        await UserService.saveBankDetails(user.id, details);
        dispatch(fetchUserDetails());
        dispatch(successAlert('Your bank details have been updated.'));
      }
    } catch (e) {
      dispatch(errorAlert('Failed to save bank details.'));
    }
  };
}

export function saveEmailSubscriptions(subscriptionPreferences) {
  return async function (dispatch, getState) {
    const { user } = getState();
    try {
      if (isUserLoggedIn(user)) {
        await UserService.saveEmailSubscriptions(
          user.id,
          subscriptionPreferences
        );
        dispatch(fetchUserDetails());
        dispatch(
          successAlert('Your subscription preferences have been updated!')
        );
      }
    } catch (e) {
      dispatch(errorAlert('Failed to save email subscriptions.'));
    }
  };
}

export function updatePassword({
  currentPassword,
  newPassword,
  newPasswordConfirm,
}) {
  return async function (dispatch, getState) {
    const { user } = getState();
    if (isUserLoggedIn(user)) {
      UserService.updatePassword(user.id, {
        currentPassword,
        newPassword,
        newPasswordConfirm,
      })
        .then(() => {
          dispatch(successAlert('Your password has been updated.'));
          history.push(settingsUrl());
        })
        .catch((err) => {
          dispatch(errorAlert('Failed to update password.'));
          console.log('errrrror!!!!', err); // eslint-disable-line no-console
        });
    } else {
      dispatch(errorAlert('Failed to update password.'));
    }
  };
}

export function contact(data) {
  return async function (dispatch) {
    try {
      await UserService.contact(data);
      const isHoliday = isXmasAndNYHoliday();
      const greeting = isHoliday
        ? 'BrickX Customer Support is closed over the holiday period and will open again on Monday, 7 January 2019. We will respond to your enquiry after this time.\n \n**NOTE**: Messages will be reviewed periodically and emergency staff will endeavour to respond to critical enquiries where possible.\n \nMerry Christmas and Happy New Year\n \nThe BrickX Team'
        : 'Thank you for contacting BrickX. Our team will review your message and respond to the email address provided. We aim to respond to all enquiries within 24 business hours (Monday - Friday).';
      dispatch(successAlert(greeting));
    } catch (e) {
      rollBarError(e.message);
      dispatch(errorAlert('Sorry, we are not able to process your enquiry.'));
    }
  };
}

export function contactWithAttachments(data) {
  return async function (dispatch) {
    try {
      dispatch(successAlert('Submitting...'));
      await UserService.contactWithAttachments(data);
      const isHoliday = isXmasAndNYHoliday();
      const greeting = isHoliday
        ? 'BrickX Customer Support is closed over the holiday period and will open again on Monday, 7 January 2019. We will respond to your enquiry after this time.\n \n**NOTE**: Messages will be reviewed periodically and emergency staff will endeavour to respond to critical enquiries where possible.\n \nMerry Christmas and Happy New Year\n \nThe BrickX Team'
        : 'Thank you for contacting BrickX. Our team will review your message and respond to the email address provided. We aim to respond to all enquiries within 24 business hours (Monday - Friday).';
      dispatch(successAlert(greeting));
    } catch (e) {
      rollBarError(e.message);
      dispatch(errorAlert('Sorry, we are not able to process your enquiry.'));
    }
  };
}

export function sendOtp(isResend = false) {
  return async function (dispatch, getState) {
    const { user } = getState();
    try {
      if (isUserLoggedIn(user)) {
        await UserService.getSmsOtp(user.id);
        if (isResend) {
          dispatch(successAlert('Resending SMS.'));
          dispatch(successAnswer(true));
        }
      }
    } catch (e) {
      dispatch(errorAlert(e.message));
      dispatch(successAnswer(false));
    }
  };
}

export const userSelector = (state) => ({
  user: state.user,
});

const reducer = handleActions(
  {
    [LOGOUT]: () => new User(),
    [GET_DETAILS]: (state, action) => new User(action.payload),
  },
  new User()
);

export default reducer;
