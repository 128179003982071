import styled from 'styled-components';
import { getThemeColor } from 'src/themes/themeUtils';
import {
  passPropsThrough,
} from 'src/design/styleguide/common/styledComponentsUtils';
import Text from 'src/design/styleguide/text/Text';

const Paragraph = styled(Text).attrs(passPropsThrough({
  as: 'p',
  fontSize: '2x-small',
  lineHeight: 'large',
}))`
  color: ${getThemeColor('grey600')};

  & + * {
    margin-top: 1em;
  }
`;

export default Paragraph;
