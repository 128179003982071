import styled from 'styled-components';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import ClonedChild from 'src/design/styleguide/common/ClonedChild';
import generateResponsiveStyles from
  'src/design/styleguide/common/generateResponsiveStyles';

export const getSizePx = (size) => ({
  'none': '0px',
  '2x-small': `${gridUnit * 1}px`,
  'x-small': `${gridUnit * 2}px`,
  'small': `${gridUnit * 3}px`,
  'normal': `${gridUnit * 4}px`,
  'large': `${gridUnit * 6}px`,
  'x-large': `${gridUnit * 8}px`,
  '2x-large': `${gridUnit * 10}px`,
  '3x-large': `${gridUnit * 12}px`,
  '4x-large': `${gridUnit * 14}px`,
  '5x-large': `${gridUnit * 16}px`,
  '6x-large': `${gridUnit * 18}px`,
  '7x-large': `${gridUnit * 20}px`,
})[size];

const generateMargin = (size, direction) => `margin-${direction}: ${getSizePx(size)};`;

const Spacing = styled(ClonedChild)`
  && {
    ${props => props.top && generateResponsiveStyles(props.top, (size) => generateMargin(size, 'top'))}
    ${props => props.right && generateResponsiveStyles(props.right, (size) => generateMargin(size, 'right'))}
    ${props => props.bottom && generateResponsiveStyles(props.bottom, (size) => generateMargin(size, 'bottom'))}
    ${props => props.left && generateResponsiveStyles(props.left, (size) => generateMargin(size, 'left'))}
  }
`;

export default Spacing;
