import classNames from 'classnames';
import Hyperlink from 'src/design/components/hyperlink/Hyperlink';
import styles from 'src/design/components/hyperlink/Styledlink.mscss';


const Styledlink = ({ fullWidth, grey, withArrow, charcoal, className, ...props }) => (
  <Hyperlink
    className={classNames(className, styles.styledLink, {
      [styles.fullWidth]: fullWidth,
      [styles.greyLink]: grey,
      [styles.charcoalLink]: charcoal,
      [styles.withArrow]: withArrow,
    })}
    {...props}
  />
);

export default Styledlink;
