import { cloneElement } from 'react';
import classNames from 'classnames';
import styles from 'src/design/components/heading/Heading.mscss';

const alignmentClass = (alignment) => `heading--align-${alignment}`;
const colorClass = (color) => `heading--color-${color}`;

export const Heading = ({ children, className, align, color, testRef }) => {
  return cloneElement(children, {
    className: classNames(
      children.props.className,
      styles.heading,
      styles[alignmentClass(align)],
      styles[colorClass(color)],
      className,
    ),
    'data-test-reference': testRef,
  });
};

export const HeadingLarge = ({ className, ...props }) =>
  <Heading
    className={classNames(className, styles['heading--text-size-large'])}
    {...props}
  />;

export const HeadingSmall = ({ className, ...props }) =>
  <Heading
    className={classNames(className, styles['heading--text-size-small'])}
    {...props}
  />;

export const HeadingXSmall = ({ className, ...props }) =>
  <Heading
    className={classNames(className, styles[`heading--text-size-x-small`])}
    {...props}
  />;

export default Heading;
